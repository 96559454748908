import React from "react"
import TrynowIcon from "../../components/icons/Trynow"
import IntelIcon from "../../components/icons/Intel"
import EvolveYouIcon from "../../components/icons/EvolveYou"
import FuturePlatformsIcon from "../../components/icons/FuturePlatforms"
import CybellumIcon from "../../components/icons/Cybellum"

export const CompaniesIcons = () => {
    return (
        <div className="icons-companies">
            <TrynowIcon width="85px" height="25px" viewBox="0 0 85 25"/>
            <IntelIcon width="40px" height="25px" viewBox="0 0 40 25"/>
            <EvolveYouIcon width="120px" height="25px" viewBox="0 0 120 25"/>
            <FuturePlatformsIcon width="90px" height="25px" viewBox="0 0 90 25"/>
            <CybellumIcon width="230px" height="25px" viewBox="0 0 230 25"/>
        </div>
    )
}
