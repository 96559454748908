import React from "react"
import BaseIcon from "./BaseIcon"

const FuturePlatformsIcon = ({ ...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_564_21304)">
            <path d="M87.7205 17.502C87.1268 16.9197 86.6604 16.2202 86.3508 15.4477C86.0412 14.6753 85.8952 13.8467 85.9221 13.0147C85.9009 12.1806 86.0477 11.3507 86.3538 10.5747C86.6599 9.79873 87.119 9.09253 87.7036 8.49833C88.2882 7.90413 88.9863 7.43414 89.7563 7.11642C90.5262 6.7987 91.3522 6.63979 92.1849 6.64917C93.2443 6.64068 94.2893 6.89522 95.2267 7.39008C96.1101 7.83481 96.8601 8.50637 97.4 9.33628L96.2545 10.2755C95.799 9.6189 95.1929 9.08153 94.4871 8.70854C93.7812 8.33555 92.9963 8.13784 92.1984 8.13204C91.5749 8.12 90.9557 8.23685 90.3793 8.47528C89.8029 8.71372 89.2817 9.06862 88.8483 9.51786C88.3962 9.97758 88.0421 10.5247 87.8076 11.1258C87.5731 11.727 87.463 12.3696 87.4841 13.0147C87.4601 13.6422 87.5652 14.2678 87.7927 14.8529C88.0202 15.4379 88.3654 15.9698 88.8067 16.4155C89.2481 16.8612 89.7762 17.2111 90.3582 17.4436C90.9402 17.6761 91.5638 17.7862 92.1901 17.767C92.9994 17.7809 93.7997 17.5952 94.5206 17.2264C95.2415 16.8575 95.8609 16.3168 96.3243 15.6518L97.4333 16.5909C96.8214 17.4199 96.022 18.0916 95.1008 18.5509C94.1796 19.0102 93.1627 19.2441 92.1338 19.2332C91.3195 19.2594 90.5084 19.1195 89.7497 18.8218C88.991 18.5242 88.3007 18.0752 87.7205 17.502Z" fill="white"/>
            <path d="M116.168 6.87058L112.032 14.6428V19.0664H110.531V14.6428L106.441 6.86432H108.107L111.278 13.1715L114.5 6.86432H116.167L116.168 6.87058Z" fill="white"/>
            <path d="M134.6 15.5715C134.611 16.4892 134.258 17.374 133.62 18.0321C133.262 18.3902 132.832 18.6682 132.359 18.8481C131.886 19.0279 131.38 19.1055 130.875 19.0757H125.967V6.87046H130.661C131.107 6.84508 131.553 6.91412 131.97 7.07308C132.387 7.23203 132.765 7.47733 133.081 7.79295C133.363 8.0716 133.587 8.40378 133.739 8.77004C133.891 9.1363 133.968 9.52927 133.967 9.92594C133.978 10.4566 133.827 10.9781 133.535 11.4207C133.243 11.8634 132.822 12.2062 132.331 12.4033C133.009 12.5996 133.601 13.0188 134.013 13.5934C134.425 14.1681 134.631 14.8648 134.6 15.5715ZM127.468 8.31889V11.821H130.426C130.678 11.8415 130.932 11.8093 131.172 11.7265C131.411 11.6436 131.63 11.5119 131.816 11.3397C132.002 11.1675 132.151 10.9585 132.252 10.7259C132.353 10.4934 132.405 10.2423 132.405 9.98855C132.396 9.74925 132.338 9.51432 132.235 9.2984C132.131 9.08249 131.985 8.89021 131.804 8.73357C131.623 8.57692 131.412 8.45926 131.184 8.38792C130.956 8.31657 130.716 8.29308 130.478 8.31889H127.468ZM130.639 17.6189C130.957 17.6358 131.274 17.5873 131.572 17.4764C131.869 17.3655 132.141 17.1945 132.37 16.974C132.579 16.7835 132.745 16.5511 132.858 16.2918C132.972 16.0325 133.029 15.7522 133.026 15.4692C133.025 15.1562 132.958 14.8469 132.83 14.5615C132.701 14.276 132.515 14.0208 132.282 13.8122C132.049 13.6036 131.775 13.4464 131.477 13.3508C131.18 13.2551 130.866 13.2231 130.555 13.2569H127.463V17.6189H130.639Z" fill="white"/>
            <path d="M143.983 19.0725V6.87043H151.388V8.32407H145.485V12.1372H150.947V13.5887H145.485V17.6168H151.406V19.0704L143.983 19.0725Z" fill="white"/>
            <path d="M161.971 19.0725V6.87043H163.473V17.5844H170.017V19.0725H161.971Z" fill="white"/>
            <path d="M179.764 19.0725V6.87043H181.266V17.5844H187.81V19.0725H179.764Z" fill="white"/>
            <path d="M197.219 6.87043H198.726V14.697C198.726 16.6943 199.74 17.7858 201.555 17.7858C203.32 17.7858 204.35 16.6317 204.35 14.697V6.87043H205.869V15.01C205.898 15.5862 205.809 16.1623 205.606 16.7024C205.404 17.2426 205.093 17.7353 204.693 18.15C204.271 18.538 203.776 18.8384 203.238 19.0336C202.699 19.2288 202.127 19.3151 201.555 19.2875C200.396 19.34 199.262 18.9312 198.402 18.15C198.001 17.7356 197.69 17.2429 197.488 16.7027C197.286 16.1625 197.197 15.5863 197.226 15.01V6.87043H197.219Z" fill="white"/>
            <path d="M216.135 19.0725V6.87043H216.786L222.086 14.7283L227.336 6.87043H228.007V19.0725H226.489V10.6324L222.254 16.9395H221.879L217.644 10.6324V19.0725H216.135Z" fill="white"/>
            <path d="M26.1359 25.1112L39.4945 0.893873H42.2844L28.9257 25.1112H26.1359Z" fill="white"/>
            <path d="M3.61836 22.0295C2.42824 20.8797 1.49194 19.4926 0.86988 17.9578C0.247823 16.4229 -0.0461629 14.7744 0.00689444 13.1187C-0.0501836 11.4372 0.247668 9.76258 0.880956 8.20439C1.51424 6.64619 2.4687 5.23954 3.68189 4.07646C4.84936 2.91359 6.2383 1.99791 7.76611 1.3839C9.29392 0.769893 10.9294 0.470097 12.5752 0.502352C14.7 0.484174 16.7964 0.991728 18.6786 1.98C20.4482 2.85818 21.954 4.19038 23.043 5.84108L20.752 7.71006C19.83 6.40341 18.6111 5.33534 17.1958 4.59411C15.7805 3.85287 14.2095 3.45975 12.6126 3.44721C11.3627 3.42127 10.1207 3.65214 8.96313 4.12559C7.80561 4.59904 6.75712 5.30504 5.88228 6.20006C4.97355 7.10764 4.26003 8.1922 3.78576 9.3868C3.31149 10.5814 3.08653 11.8607 3.12475 13.1458C3.07951 14.4014 3.29515 15.6527 3.75801 16.8204C4.22088 17.9881 4.92087 19.0466 5.81356 19.9288C6.70546 20.8025 7.76355 21.4876 8.92495 21.9433C10.0863 22.399 11.3273 22.616 12.5741 22.5815C14.1937 22.6087 15.7958 22.2414 17.2424 21.511C18.689 20.7806 19.937 19.7091 20.879 18.3886L23.1117 20.2575C21.8749 21.9067 20.2678 23.2407 18.4205 24.1514C16.5733 25.0621 14.5379 25.5238 12.4794 25.4992C10.8467 25.5371 9.22287 25.2499 7.70183 24.6543C6.18079 24.0587 4.79283 23.1665 3.61836 22.0295Z" fill="white"/>
            <path d="M55.6674 25.5024C53.6089 25.527 51.5735 25.0652 49.7263 24.1545C47.879 23.2438 46.2719 21.9099 45.0351 20.2607L47.2678 18.3917C48.2095 19.7126 49.4574 20.7843 50.9041 21.5147C52.3508 22.2451 53.953 22.6123 55.5727 22.5846C56.8195 22.6192 58.0604 22.4021 59.2218 21.9464C60.3832 21.4907 61.4413 20.8056 62.3332 19.932C63.2259 19.0497 63.9259 17.9912 64.3888 16.8235C64.8516 15.6559 65.0673 14.4046 65.022 13.149C65.0609 11.8638 64.8362 10.5843 64.3619 9.38959C63.8876 8.19489 63.1737 7.11041 62.2645 6.20319C61.39 5.30772 60.3416 4.60143 59.184 4.12795C58.0264 3.65446 56.7842 3.42385 55.5341 3.45034C53.9369 3.46161 52.3653 3.85418 50.9496 4.59551C49.534 5.33685 48.315 6.4056 47.3938 7.71319L45.1028 5.84421C46.1899 4.19196 47.6963 2.85934 49.4671 1.98313C51.3481 0.995369 53.4429 0.486815 55.5664 0.502352C57.2122 0.470097 58.8476 0.769893 60.3755 1.3839C61.9033 1.99791 63.2922 2.91359 64.4597 4.07646C65.6723 5.23994 66.6265 6.64667 67.2597 8.20477C67.893 9.76286 68.1911 11.4373 68.1347 13.1187C68.1882 14.7743 67.8944 16.4228 67.2723 17.9575C66.6502 19.4923 65.7137 20.8791 64.5232 22.0284C63.3504 23.1665 61.9635 24.0596 60.4432 24.656C58.923 25.2524 57.2996 25.54 55.6674 25.5024Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_564_21304">
                <rect width="228" height="25" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </BaseIcon>
)

export default FuturePlatformsIcon
